<template>
  <div style="padding: 20px">
    <el-button type="primary" size="default" :loading="loading" @click="handleClick">
      快照
    </el-button>
    <p>
      本接口较慢（大于1分钟，5分钟内），且后端限制24小时内仅能调用一次，请不要刷新和重复点击快照
    </p>
  </div>
</template>

<script>
import { eventsSnapshot } from "@/api/nft.js";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleClick() {
      this.loading = true;
      try {
        await eventsSnapshot();
        this.$message.success("完成");
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
